import LazyLoad from "react-lazyload";
const Img = ({source}) => {

    return (
        <>
            <LazyLoad height={200} offset={100}>
                <img src={source} className="rounded-3 img-fluid" />
            </LazyLoad>
        </>
    )

}
export default Img;