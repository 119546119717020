import './../App.css';
const Animation = () => {

    return(
        <>
            <div className="background-animation">
                <div className="circle circle1"></div>
                <div className="circle circle2"></div>
                <div className="circle circle3"></div>
            </div>
        </>
    )

}

export default Animation;