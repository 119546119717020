import Container from 'react-bootstrap/Container';
import './../../App.css';
import Animation from '../animation';

const Banner = () => {
    return (
        <>
        <Animation/>
            <Container className='py-5'>
                <div className='row place-item-center'>
                    <div className='col-lg-6' 
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                    >
                        <img src='/images/profile1.jpg' className='img-fluid bannerRadius shadow-lg'
                            alt="Profile" />
                    </div>
                    <div className='col-lg-5 d-lg-flex d-block text-lg-start text-center mt-lg-0 mt-5 justify-content-center flex-column'
                        data-aos="fade-down-left"
                    >
                        <h1 className='fw-bold'>Laegan S. Pangantihon</h1>
                        <h3>Web Developer</h3>
                    </div>
                </div>
            </Container>
            
        </>
    );
}

export default Banner;
